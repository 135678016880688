<template>
  <div class="report">
    <div class="report__navbar">
      <mt-navbar v-model="selectedTab">
        <mt-tab-item id="summaryReport" class="report__navbar__tab">
          综合报告
        </mt-tab-item>
        <mt-tab-item id="classroomReport" class="report__navbar__tab">
          班级报告
        </mt-tab-item>
        <mt-tab-item id="itemReport" class="report__navbar__tab">
          试题报告
        </mt-tab-item>
      </mt-navbar>
    </div>
    <div class="report__content" v-if="reportVisible">
      <summary-report
        v-if="selectedTab === 'summaryReport'"
        :report="classroomReport"
        @view-classroom-report="view__classroomReport">
      </summary-report>
      <classroom-report
        v-if="selectedTab === 'classroomReport'"
        :key="classroomReport_currentClassroom.classroomId"
        :report="classroomReport"
        :current-classroom="classroomReport_currentClassroom"
        @change-current-classroom="change__classroomReport_currentClassroom"
        @remove-quiz-answer="refreshReport"
        @edit-quiz-answer="refreshReport">
      </classroom-report>
      <item-report
        v-if="selectedTab === 'itemReport'"
        :report="itemReport"
        :classrooms="classroomReport.classrooms"
        :current-classroom="classroomReport_currentClassroom"
        @change-current-classroom="change__classroomReport_currentClassroom"
      >
      </item-report>
    </div>
  </div>
</template>

<script>
  import SummaryReport from './_report/SummaryReport';
  import ClassroomReport from './_report/ClassroomReport';
  import ItemReport      from './_report/ItemReport';

  import Indicator from '@/components/__mint-ui/Indicator';
  import Toast     from '@/components/__mint-ui/Toast';

  import urls from '@/api/teacher-urls';
  import webviewService from '@/services/webview';

  import { findWhere } from 'underscore';

  export default {
    components: {
      SummaryReport,
      ClassroomReport,
      ItemReport
    },

    props: ['quiz'],

    data () {
      return {
        classroomReport: null,
        classroomReport_currentClassroom: null,

        itemReport: null,

        selectedTab: 'summaryReport'
      }
    },

    computed: {
      reportVisible () {
        return (this.summaryReport !== null && this.classroomReport !== null && this.itemReport !== null);
      }
    },

    methods: {
      __fetchClassroomReport ({ quizId }) {
        const url = urls['quizReportClassroom']({ quizId });
        return this.$http.get(url);
      },
      __fetchItemReport ({ quizId }) {
        const url = urls['quizReportItem']({ quizId });
        return this.$http.get(url);
      },
      async __fetchReport ({ quizId }) {
        let classroomReportRep = await this.__fetchClassroomReport({ quizId });
        let itemReportRep      = await this.__fetchItemReport({ quizId });

        return { classroomReportRep, itemReportRep };
      },

      change__classroomReport_currentClassroom ({ classroom }) {
        this.classroomReport_currentClassroom = classroom;
      },

      view__classroomReport(classroom) {
        this.classroomReport_currentClassroom = classroom;
        this.selectedTab = 'classroomReport';
        window.scrollTo(0,0);
      },

      refreshReport () {
        this.__fetchReport({ quizId: this.quiz.quizId })
            .then(
              rep => {
                let itemReport      = rep.itemReportRep.data;
                let classroomReport = rep.classroomReportRep.data;

                if (classroomReport.classrooms.length > 0) {
                  this.itemReport                       = itemReport;
                  this.classroomReport                  = classroomReport;
                  this.classroomReport_currentClassroom = (() => {
                                                            let classrooms   = classroomReport.classrooms;
                                                            let classroomId  = this.classroomReport_currentClassroom.classroomId;

                                                            return findWhere(classrooms, { classroomId })
                                                                 ? findWhere(classrooms, { classroomId })
                                                                 : classrooms[0];
                                                          })();
                }
                else {
                  Toast.showTip('测验报告已被清空');
                  this.$emit('empty-report');
                }
              }
            );
      }
    },

    created () {
      Indicator.open(this.$i18n.t('__Common.loading'));

      this.__fetchReport({ quizId: this.quiz.quizId })
          .then(
            rep => {
              this.itemReport                       = rep.itemReportRep.data;
              this.classroomReport                  = rep.classroomReportRep.data;
              this.classroomReport_currentClassroom = this.classroomReport.classrooms[0];

              Indicator.close();
            },
            err => {
              Indicator.close();
            }
          );
    },

    beforeCreate () {
      webviewService.pushBackButtonCallback('quiz.report', () => this.$router.push({ name: 'teacherQuizList' }));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('quiz.report');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .report {
        margin-top: 0.57rem;

        .report__navbar {
          border-bottom: 1px solid $gray;
        }
  }

</style>
