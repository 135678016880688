<template>
    <div id="app">
        <unsupported v-if="unsupported"></unsupported>
        <template v-else>
            <keep-alive include="QuizList">
                <router-view></router-view>
            </keep-alive>
        </template>
        <network-issue-bar v-show="networkStatus === 'offline'"></network-issue-bar>
    </div>
</template>
<script>
    import Unsupported from '@/entries/Unsupported'
    import MessageBox from '@/components/__mint-ui/MessageBox';
    import NetworkIssueBar from '@/components/NetworkIssueBar';
    import webviewService from '@/services/webview';


    import {
        Plugins
    } from '@capacitor/core';

    const {
        Network
    } = Plugins;

    export default {
        data() {
            return {
                unsupported: false
            }
        },
        components: {
            NetworkIssueBar,
            Unsupported
        },
        computed: {
            networkStatus() {
                return this.$store.state.networkStatus;
            }
        },
        methods: {
            showPrompt() {
                MessageBox.open({
                        message: '确认退出门口易测吗?',
                        showConfirmButton: true,
                        showCancelButton: true
                    },
                    () => webviewService.exitApp()
                );
            },
            rootPageBackHandler() {
                history.pushState({}, '', `${location.pathname}${location.hash}`)
                webviewService.exitApp()
            }
        },

        beforeCreate() {

            Network.addListener('networkStatusChange', (status) => {
                console.log("Network status changed", status);
                if (status.connected) {
                    this.$store.commit('SET_NETWORK_STATUS', {
                        networkStatus: 'online'
                    })
                } else {
                    this.$store.commit('SET_NETWORK_STATUS', {
                        networkStatus: 'offline'
                    })
                }
            });

            webviewService.pushBackButtonCallback('app', () => this.showPrompt());
        },
        watch: {
            '$route': {
                handler: function() {
                    window.removeEventListener('popstate', this.rootPageBackHandler);

                    if (/teacherClassroomList|teacherQuizList|teacherProfile/.test(this.$route.name)) {
                        history.pushState({}, '', `${location.pathname}${location.hash}`);
                        window.addEventListener('popstate', this.rootPageBackHandler);
                    }
                }
            }
        },
        created() {
            console.log(this.$store.state.deviceInfo)

            // check for unsupported browsers
            if (this.$store.state.deviceInfo.platform === 'web') {
                this.unsupported = webviewService.check_web_unsupported();
            }
        },
        mounted() {

            window.addEventListener('popstate', this.rootPageBackHandler);
            window.setTimeout(
                () => {
                    webviewService.changeStatusBarColor('#26a2ff');
                    webviewService.hideSplashScreen();
                    webviewService.update(false)
                },
                500
            );
        }
    };
</script>
<style lang="scss">
    @import 'src/assets/style/globals';

    #app {
        margin: 0 auto;
    }
</style>