<template>
  <div class="item-report">
    <div class="item-report__table">
      <div class="item-report__table-header" @click="sortPopupVisible = true">
        <span class="item-report__table-header__item">题号</span>
        <span class="item-report__table-header__item">正确率</span>

        <span class="item-report__sort-button">
          <i class="fas fa-sort"></i>
        </span>
      </div>
      <sort-popup v-if="sortPopupVisible" :sort-key="sortKey" @click-sort-by-item-ordinal-button="sortByItemOrdinal"
        @click-sort-by-correct-rate-button="sortByCorrectRate" @click-mask="sortPopupVisible = false">
      </sort-popup>
      <div class="item-report__table-content">
        <list-sort-transition>
          <item-card v-for="(item, index) in items" :item="item" :key="item.itemOrdinal" :is-first="index === 0" :current-classroom="currentClassroom"
            @click-item="showItemModal">
          </item-card>
        </list-sort-transition>
      </div>
      <modal-transition>
        <item-modal v-if="itemModalVisible" :item="itemForModal" @close-modal="hideItemModal" :current-classroom="currentClassroom">
        </item-modal>
      </modal-transition>
      <div class="item-report__select-classroom-bar" :class="{ 'is-list-visible': classroomListVisible }"
        @click="classroomListVisible = !classroomListVisible">
        <span class="item-report__classroom-name">
          <i class="fa fa-address-book-o"></i>
          {{ currentClassroom.classroomName }}
        </span>
        <span class="item-report__select-classroom-button">
          <i class="fa fa-angle-double-up" v-show="!classroomListVisible"></i>
          <i class="fa fa-angle-double-down" v-show="classroomListVisible"></i>
          {{ $t("TeacherQuiz.label.switch") }}
        </span>
      </div>
      <div v-if="classroomListVisible">
        <transition name="fade">
          <div class="item-report__classroom-list">
            <label class="item-report__classroom-item" v-for="classroom in classrooms" :key="classroom.id">
              <div class="item-report__classroom-item__selector">
                <input type="checkbox" :checked="classroom === currentClassroom"
                  :disabled="classroom === currentClassroom" @change="selectClassroom(classroom)" />
                <span></span>
              </div>
              <span class="item-report__classroom-item__classroom-name">
                {{ classroom.classroomName }}
              </span>
              <span class="item-report__classroom-item__summary">
                <span>
                  {{ $t("TeacherQuiz.label.correctRate") }}:
                  {{ formatRatio(classroom.summary.averageScoreRatio) }}
                </span>
                <span>
                  {{ $t("TeacherQuiz.label.scanOverview") }}:
                  {{ hasResultTakersCount(classroom) }}/{{
                  classroom.summary.takersTotal
                }}
                </span>
              </span>
            </label>
          </div>
        </transition>
        <div class="item-report__classroom-list-mask" @click="classroomListVisible = false">
          <mask-layer></mask-layer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ListSortTransition from '@/components/ListSortTransition';
  import ModalTransition from '@/components/ModalTransition';
  import MaskLayer from "@/components/MaskLayer";

  import SortPopup from './_item-report/SortPopup';
  import ItemCard from './_item-report/ItemCard';
  import ItemModal from './_item-report/ItemModal';

  import Toast from '@/components/__mint-ui/Toast';

  export default {
    components: {
      ListSortTransition,
      ModalTransition,
      MaskLayer,
      SortPopup,
      ItemCard,
      ItemModal
    },

    props: ['report', 'classrooms', 'currentClassroom'],
    data() {
      return {
        sortKey: '',

        sortPopupVisible: false,
        itemModalVisible: false,
        classroomListVisible: false,
        itemForModal: null
      };
    },

    computed: {
      items() {
        return this.report.items;
      }
    },

    methods: {
      formatRatio(ratio) {
        return (ratio * 100).toFixed(0) + "%";
      },
      hasResultTakersCount(classroom) {
        return classroom.quizTakeableEntries.filter(
          (e) => e.quizAnswerResult !== null
        )["length"];
      },
      selectClassroom(classroom) {
        this.classroomListVisible = false;
        this.sortKey = "";
        this.$emit("change-current-classroom", {
          classroom
        });
      },
      sortByItemOrdinal() {
        this.items.sort((item1, item2) => item1.itemOrdinal - item2.itemOrdinal);
        this.sortKey = 'itemOrdinal';
        this.sortPopupVisible = false;
      },
      sortByCorrectRate() {
        this.items.sort((item1, item2) => {
          if (item1.averageScoreRatio === null)
            return 1;
          else if (item2.averageScoreRatio === null)
            return -1
          else
            return (item2.averageScoreRatio - item1.averageScoreRatio);
        });

        this.sortKey = 'correctRate';
        this.sortPopupVisible = false;
      },

      showItemModal(item) {
        if (item.type === 'subjectiveSkip') {
          Toast.showMessage('该题为主观题');
        } else {
          this.itemForModal = item;
          this.itemModalVisible = true;
        }
      },
      hideItemModal() {
        this.itemForModal = null;
        this.itemModalVisible = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';
  @import 'src/assets/style/mixins';

  .item-report {

    &__classroom-filters {
      height: 1.2rem;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $blue;
      color: $white;
      z-index: $high-z-index;
    }


    &__table-header {
      @include pressed(0, 0, 0, 0);

      position: relative;
      padding: .3rem;
      display: flex;

      &__item {
        flex: 1;
        font-size: $h5;
        // font-weight: bold;
      }
    }

    &__sort-button {
      position: absolute;
      top: .25rem;
      right: .3rem;
      font-size: $h4;
    }

    &__table-content {
      background-color: $white;
      border-bottom: 1px solid $light-gray;
      margin-bottom: 1.3rem;
    }

    &__select-classroom-bar {
      @include pressed(0, 0, 0, 0);

      position: fixed;
      z-index: $highest-z-index;
      bottom: 0;
      width: 100%;
      height: 1.2rem;
      background-color: $blue;
      color: $white;
      line-height: 1.2rem;
      text-align: left;

      &.is-list-visible {
        z-index: $highest-z-index + 2;
      }
    }

    &__classroom-name {
      @include truncate(75%);
      float: left;
      margin-left: 0.3rem;
      display: inline-block;
      font-size: $h3;
    }

    &__select-classroom-button {
      margin-right: 0.3rem;
      float: right;
      font-size: $h4;

      i {
        font-size: $h3;
      }
    }

    &__classroom-list {
      position: fixed;
      z-index: $highest-z-index + 2;
      bottom: 1.2rem;
      padding-top: 0.3rem;
      width: 100%;
      height: 5.7rem;
      max-height: 5.7rem;
      background-color: $blue;
      border-top: 1px solid $light-gray;
      box-shadow: 0 1px 1px -1px $light-gray;
      color: $white;
      overflow-y: auto;
    }

    &__classroom-item {
      margin-top: 0.1rem;
      display: block;
      height: 0.8rem;
      font-size: $h4;

      &__classroom-name {
        @include truncate(3.5rem);
        display: inline-block;
        vertical-align: middle;
      }

      &__summary {
        color: $light-gray;
        font-size: $h5;

        span {
          margin-left: 0.3rem;
        }
      }

      &__selector {
        display: inline-block;
        height: 1.2rem;
        width: 1rem;
        text-align: center;

        input {
          display: none;
        }

        span {
          position: relative;
          display: inline-block;
          width: 0.5rem;
          height: 0.5rem;
          background-color: $white;
          border-radius: 0;
          border: 1px solid $gray;
          vertical-align: middle;

          &:after {
            position: absolute;
            top: 0.1rem;
            left: 0.2rem;
            width: 0.1rem;
            height: 0.2rem;
            border: 2px solid transparent;
            border-left: 0;
            border-top: 0;
            content: "";
            transform: rotate(45deg) scale(0);
            transition: transform 0.2s, -webkit-transform 0.2s;
            -webkit-transform: rotate(45deg); // For android 4.4
          }
        }

        input:checked+span:after {
          border-color: $blue;
          transform: rotate(45deg) scale(1);
        }
      }
    }

    .fade-enter-active {
      transition: all 0.5s ease;
    }

    .fade-leave-active {
      transition: all 1s ease;
    }

    .fade-enter,
    .fade-leave-to {
      transform: translateY(1.2rem);
      opacity: 0;
    }
  }
</style>