<template>
  <div class="settings">
    <div class="settings__header">
      <mt-header fixed title="设置">
        <router-link to="/teacher/profile" slot="left">
          <mt-button icon="back"></mt-button>
        </router-link>
      </mt-header>
    </div>
    <div class="settings__main">
      <div
        class="settings__item"
        @click="$router.push({ name: 'teacherEditAccount' })"
      >
        <mt-cell title="账号设置"></mt-cell>
      </div>
      <div class="settings__item" @click="Tucao">
        <mt-cell title="吐个槽"></mt-cell>
      </div>
      <div class="settings__item" @click="$router.push({ name: 'about' })">
        <mt-cell title="关于"></mt-cell>
      </div>
      <!-- <img class="settings__logo" src="~images/logo.png" />
      <div class="settings__current-version">
        <p class="settings__title">门口易测</p>
        <p class="settings__version"><small>{{$store.state.deviceInfo.platform}}app v{{`${$store.state.deviceInfo.appVersion}:${$store.state.deviceInfo.appBuild}`}}</small></p>
      </div>
      <div class="settings__check-update" v-if="$store.state.deviceInfo.platform !== 'ios'" @click="update()">检查新版本</div> -->
    </div>
  </div>
</template>

<script>
import MessageBox from "@/components/__mint-ui/MessageBox";
import checkAccess from "@/services/check-access";
import webviewService from "@/services/webview";

export default {
  methods: {
    Tucao() {
      let dv = this.$store.state.deviceInfo;
      let acc = this.$store.state.session.account;
      // let deviceInfoString = [`${dv.platform}-${dv.appVersion}.${dv.appBuild}`,`${dv.operatingSystem}-${dv.osVersion}`,`${dv.manufacturer}-${dv.model}`].join('/')
      let clientInfo = `${dv.platform}/${dv.manufacturer}-${dv.model}`;
      let clientVersion = `${dv.appVersion}.${dv.appBuild}`;
      let os = dv.operatingSystem;
      let osVersion = dv.osVersion;

      const tucaoUrl = "https://mkyice-app.menco.cn/tucao.html";
      const qs = `?o=${encodeURIComponent(acc.userId)}&n=${encodeURIComponent(
        acc.fullname
      )}&clientInfo=${encodeURIComponent(
        clientInfo
      )}&clientVersion=${encodeURIComponent(
        clientVersion
      )}&os=${encodeURIComponent(
        os
      )}&osVersion=${encodeURIComponent(osVersion)}`;

      webviewService.openLink(tucaoUrl + qs);
    },
  },
  beforeCreate() {
    checkAccess("loggedIn");
    webviewService.pushBackButtonCallback("settings", () =>
      this.$router.push({
        name: "teacherProfile",
      })
    );
  },
  beforeDestroy() {
    webviewService.popBackButtonCallback("settings");
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/variables";

.settings {
  &__header {
    position: relative;
    height: $header-height;
  }

  &__main {
    margin-top: 0.5rem;
  }
}
</style>
