<template>
  <div class="bubblesheet">
    <div v-if="itemsCount > 0">
      <div class="bubblesheet__prompt-bar" v-if="hasAnswerResult">
        当前测验已有成绩录入，只支持修改试题正确答案和分数。
      </div>
      <div
        class="bubblesheet__summary-bar"
        :class="{ 'is-move-down': hasAnswerResult }"
      >
        <summary-bar :items="items"></summary-bar>
      </div>
      <div
        class="bubblesheet__options-bar"
        :class="{ 'is-move-down': hasAnswerResult }"
      >
        <span
          class="bubblesheet__edit-count-button"
          :class="{ 'is-disabled': hasAnswerResult }"
          @click="toUpdateItemsCount()"
        >
          <!-- <i class="fa fa-pencil-square-o"></i> -->
          <i class="fas fa-pencil-ruler"></i>
          {{ $t('TeacherQuiz.label.modifyCount') }}
        </span>
        <modal-transition>
          <edit-count-modal
            v-if="editCountModalVisible"
            :count="itemsCount"
            @close-modal="editCountModalVisible = false"
            @click-confirm-button="updateItemsCount_fromEditCountModal"
          >
          </edit-count-modal>
        </modal-transition>
        <span class="bubblesheet__download-button" @click="openDownload">
          <i class="fa fa-download"></i>
          {{ $t('TeacherQuiz.label.downloadBubblesheet') }}
        </span>
        <modal-transition>
          <download-bubblesheet-modal
            v-if="downloadBubblesheetModalVisible"
            :quiz="quiz"
            :downlodQuery="downlodQuery"
            @close-modal="downloadBubblesheetModalVisible = false"
          >
          </download-bubblesheet-modal>
        </modal-transition>
        <span
          v-show="!isAllSelected"
          class="bubblesheet__select-all-button"
          @click="selectAll()"
        >
          {{ $t('TeacherQuiz.label.selectAll') }}
        </span>
        <span
          v-show="isAllSelected"
          class="bubblesheet__unselect-all-button"
          @click="unselectAll()"
        >
          {{ $t('TeacherQuiz.label.unselectAll') }}
        </span>
      </div>
      <div
        class="bubblesheet__item-list"
        :class="{ 'is-move-down': hasAnswerResult }"
      >
        <list-transition>
          <item-card
            v-for="item in items"
            :item="item"
            :quiz="quiz"
            :key="item.itemOrdinal"
            @click-selector="item.selected = !item.selected"
            @click-remove-button="removeItem"
            @click-edit-button="toEditItem"
            @click-set-score-button="toSetItemScore"
            @click-choice-item="updateItemChoices"
          >
          </item-card>
        </list-transition>
        <modal-transition>
          <edit-item-modal
            v-if="editItemModalVisible"
            @close-modal="editItemCanceled"
            @click-confirm-button="editItemDone"
            :item="itemForEdit"
          >
          </edit-item-modal>
        </modal-transition>
        <modal-transition>
          <edit-items-modal
            v-if="editItemsModalVisible"
            @close-modal="editItemsCanceled"
            @click-confirm-button="editItemsDone"
            :items="itemsForEdit"
          >
          </edit-items-modal>
        </modal-transition>
        <modal-transition>
          <set-item-score-modal
            v-if="setItemScoreModalVisible"
            @close-modal="setItemScoreCanceled"
            @click-confirm-button="setItemScoreDone"
            :item="itemForSetScore"
          >
          </set-item-score-modal>
        </modal-transition>
        <modal-transition>
          <set-items-score-modal
            v-if="setItemsScoreModalVisible"
            @close-modal="setItemsScoreCanceled"
            @click-confirm-button="setItemsScoreDone"
            :items="itemsForSetScore"
          >
          </set-items-score-modal>
        </modal-transition>
      </div>
      <div class="bubblesheet__operation" v-show="selectedItemsCount > 0">
        <mt-button
          class="bubblesheet__remove-button"
          type="primary"
          :disabled="hasAnswerResult"
          @click="removeSelectedItems()"
        >
          <i class="fa fa-trash-o"></i>
          删除({{ selectedItemsCount }})
        </mt-button>
        <mt-button
          class="bubblesheet__edit-button"
          type="primary"
          :disabled="hasAnswerResult"
          @click="toEditItems()"
        >
          <i class="fa fa-pencil-square-o"></i>
          编辑({{ selectedItemsCount }})
        </mt-button>
        <mt-button
          class="bubblesheet__set-score-button"
          type="primary"
          :disabled="selectedSubjectiveSkipItemsCount > 0"
          @click="toSetItemsScore()"
        >
          <i class="fa fa-pencil"></i>
          设置分数({{ selectedItemsCount }})
        </mt-button>
      </div>
    </div>

    <div v-else>
      <div class="bubblesheet__empty-bubblesheet">
        <empty-bubblesheet
          @click-confirm-button="updateItemsCount"
        ></empty-bubblesheet>
      </div>
    </div>
  </div>
</template>

<script>
import ListTransition from '@/components/ListTransition'
import ModalTransition from '@/components/ModalTransition'

import SummaryBar from './_bubblesheet/SummaryBar'
import ItemCard from './_bubblesheet/ItemCard'
import EmptyBubblesheet from './_bubblesheet/EmptyBubblesheet'
import EditCountModal from './_bubblesheet/EditCountModal'
import EditItemModal from './_bubblesheet/EditItemModal'
import EditItemsModal from './_bubblesheet/EditItemsModal'
import SetItemScoreModal from './_bubblesheet/SetItemScoreModal'
import SetItemsScoreModal from './_bubblesheet/SetItemsScoreModal'
import DownloadBubblesheetModal from './_bubblesheet/DownloadBubblesheetModal'

import Toast from '@/components/__mint-ui/Toast'
import MessageBox from '@/components/__mint-ui/MessageBox'

import urls from '@/api/teacher-urls'
import webviewService from '@/services/webview'
import { findWhere, without, range, last, debounce } from 'underscore'

/* 拼接 url */
const setUrlQuery = (options) => {
  let { url, query } = options
  if (query) {
    let queryArr = []
    for (const key in query) {
      if (query.hasOwnProperty(key)) {
        queryArr.push(`${key}=${query[key]}`)
      }
    }
    if (url.indexOf('?') !== -1) {
      url = `${url}&${queryArr.join('&')}`
    } else {
      url = `${url}?${queryArr.join('&')}`
    }
  }
  return url
}

/* 缩短 数组 */
const shortArr = (arr) => {
  arr.sort((a, b) => a - b)
  const newarr = []
  let currentIdx = 0
  function fn() {
    if (currentIdx === arr.length - 1) return newarr.push(arr[currentIdx])
    let result = ''
    for (let idx = currentIdx; idx < arr.length; idx++) {
      if (idx === currentIdx) result += arr[idx]
      if (idx + 1 === arr.length) {
        result += '-' + arr[idx] + ''
        newarr.push(result)
        return
      }
      if (arr[idx] + 1 !== arr[idx + 1]) {
        result += `-${arr[idx]}`
        currentIdx = ++idx
        newarr.push(result)
        return fn()
      }
    }

  }
  fn()
  // console.log(newarr, arr, 'new old')
  return newarr
}

export default {
  components: {
    ListTransition,
    ModalTransition,
    SummaryBar,
    ItemCard,
    EmptyBubblesheet,
    EditCountModal,
    EditItemModal,
    EditItemsModal,
    SetItemScoreModal,
    SetItemsScoreModal,
    DownloadBubblesheetModal
  },

  props: ['quiz'],

  data() {
    let items =
      this.quiz.quizPaper.bubbleSheetStructure.items.map(item => Object.assign({}, item, { selected: false }))

    return {
      items,

      itemForEdit: null,
      itemsForEdit: null,

      itemForSetScore: null,
      itemsForSetScore: null,

      editCountModalVisible: false,
      editItemModalVisible: false,
      editItemsModalVisible: false,
      setItemScoreModalVisible: false,
      setItemsScoreModalVisible: false,
      downloadBubblesheetModalVisible: false,

      downlodQuery: ''
    }
  },

  computed: {
    hasAnswerResult() {
      return (this.quiz.quizSummary.answerResultsTotal > 0)
    },

    itemsCount() {
      return this.items.length
    },
    selectedItemsCount() {
      return this.items.filter(item => item.selected)['length']
    },
    selectedSubjectiveSkipItemsCount() {
      return this.items.filter(item => item.selected && item.type === 'subjectiveSkip')['length']
    },
    isAllSelected() {
      return !this.items.find(item => item.selected === false)
    },

    itemsForApi() {
      return this.items.map(item => {
        if (item.type === 'subjectiveSkip')
          return { itemOrdinal: item.itemOrdinal, type: item.type, fullScore: item.fullScore }
        else
          return { itemOrdinal: item.itemOrdinal, type: item.type, choices: item.choices, fullScore: item.fullScore }
      })
    }
  },

  methods: {
    __recountItemOrdinal() {
      this.items =
        this.items.map((item, index) => Object.assign(item, { itemOrdinal: (index + 1) }))
    },

    selectAll() {
      this.items.forEach(item => item.selected = true)
    },
    unselectAll() {
      this.items.forEach(item => item.selected = false)
    },

    removeItem(item) {
      if (this.hasAnswerResult)
        return Toast.showWarning('该测验已有成绩录入，不支持当前操作')

      MessageBox.open(
        {
          message: `确定删除第${item.itemOrdinal}题吗?`,
          showConfirmButton: true,
          showCancelButton: true
        },
        () => {
          this.items = without(this.items, item)
          this.__recountItemOrdinal()

          Toast.showTip('删除成功')
          this.unselectAll()
          this.$emit('update-bubblesheet-structure', this.itemsForApi)
        }
      )
    },
    removeSelectedItems() {
      if (this.hasAnswerResult)
        return Toast.showWarning('该测验已有成绩录入，不支持当前操作')

      MessageBox.open(
        {
          message: `确定删除选中的${this.selectedItemsCount}道题吗?`,
          showConfirmButton: true,
          showCancelButton: true
        },
        () => {
          this.items = this.items.filter(item => !item.selected)
          this.__recountItemOrdinal()

          Toast.showTip('删除成功')
          this.unselectAll()
          this.$emit('update-bubblesheet-structure', this.itemsForApi)
        }
      )
    },

    toEditItem(item) {
      if (this.hasAnswerResult)
        return Toast.showWarning('该测验已有成绩录入，不支持当前操作')

      this.itemForEdit = item
      this.editItemModalVisible = true
    },
    editItemCanceled() {
      this.itemForEdit = null
      this.editItemModalVisible = false
    },
    editItemDone(newAttrs) {
      let itemOrdinal = this.itemForEdit.itemOrdinal
      let item = findWhere(this.items, { itemOrdinal })

      if (newAttrs.type === 'subjectiveSkip') this.$delete(item, 'choices')
      item = Object.assign(item, newAttrs)

      this.itemForEdit = null
      this.editItemModalVisible = false

      Toast.showTip('试题编辑成功')
      this.unselectAll()
      this.$emit('update-bubblesheet-structure', this.itemsForApi)
    },

    toEditItems() {
      if (this.hasAnswerResult)
        return Toast.showWarning('该测验已有成绩录入，不支持当前操作')

      let items = this.items.filter(item => item.selected)

      if (items.length === 1) {
        this.toEditItem(items[0])
      }
      else {
        this.itemsForEdit = items
        this.editItemsModalVisible = true
      }
    },
    editItemsCanceled() {
      this.itemsForEdit = null
      this.editItemsModalVisible = false
    },
    editItemsDone(newAttrs) {
      this.itemsForEdit.forEach(itemForEdit => {
        let itemOrdinal = itemForEdit.itemOrdinal
        let item = findWhere(this.items, { itemOrdinal })

        if (newAttrs.type === 'subjectiveSkip') this.$delete(item, 'choices')
        item = Object.assign(item, newAttrs)
      })

      this.itemsForEdit = null
      this.editItemsModalVisible = false

      Toast.showTip('试题编辑成功')
      this.unselectAll()
      this.$emit('update-bubblesheet-structure', this.itemsForApi)
    },

    toSetItemScore(item) {
      this.itemForSetScore = item
      this.setItemScoreModalVisible = true
    },
    setItemScoreCanceled() {
      this.itemForEdit = null
      this.setItemScoreModalVisible = false
    },
    setItemScoreDone(newFullScore) {
      let itemOrdinal = this.itemForSetScore.itemOrdinal
      let item = findWhere(this.items, { itemOrdinal })

      item.fullScore = newFullScore

      this.itemForSetScore = null
      this.setItemScoreModalVisible = false

      Toast.showTip('分数设置成功')
      this.unselectAll()
      this.$emit('update-bubblesheet-structure', this.itemsForApi)
    },

    toSetItemsScore() {
      if (this.selectedSubjectiveSkipItemsCount > 0)
        return Toast.showWarning('主观题不支持设置分数')

      let items = this.items.filter(item => item.selected && item.type !== 'subjectiveSkip')

      if (items.length === 1) {
        this.toSetItemScore(items[0])
      }
      else {
        this.itemsForSetScore = items
        this.setItemsScoreModalVisible = true
      }
    },
    setItemsScoreCanceled() {
      this.itemsForSetScore = null
      this.setItemsScoreModalVisible = false
    },
    setItemsScoreDone(newFullScore) {
      this.itemsForSetScore.forEach(itemForSetScore => {
        let itemOrdinal = itemForSetScore.itemOrdinal
        let item = findWhere(this.items, { itemOrdinal })

        item.fullScore = newFullScore
      })

      this.itemsForSetScore = null
      this.setItemsScoreModalVisible = false

      Toast.showTip('分数设置成功')
      this.unselectAll()
      this.$emit('update-bubblesheet-structure', this.itemsForApi)
    },

    updateItemChoices(choices, itemOrdinal) {
      let itemToUpdate = findWhere(this.items, { itemOrdinal })
      itemToUpdate.choices = choices

      this.$emit('update-bubblesheet-structure', this.itemsForApi)
    },

    toUpdateItemsCount() {
      if (this.hasAnswerResult)
        return Toast.showWarning('该测验已有成绩录入，不支持当前操作')

      this.editCountModalVisible = true
    },
    updateItemsCount_fromEditCountModal(newCount) {
      this.editCountModalVisible = false
      this.updateItemsCount(newCount)
    },
    updateItemsCount(newCount) {
      let originCount = this.itemsCount

      if (newCount === originCount) return

      if (newCount > originCount) {
        let startItemOrdinal = last(this.items) ? last(this.items)['itemOrdinal'] : 0

        range(newCount - originCount)
          .forEach((u, i) => {
            this.items.push({
              itemOrdinal: startItemOrdinal + (i + 1),
              type: 'single',
              choices: '0000',
              fullScore: 1,
              selected: false
            })
          })
      }
      else if (newCount < originCount) {
        this.items = this.items.slice(0, newCount)
      }

      Toast.showTip('修改试题数量成功')
      this.unselectAll()
      this.$emit('update-bubblesheet-structure', this.itemsForApi)
    },

    computedQuery() {
      // console.time('query run ...')
      const paper = this.quiz?.quizPaper?.bubbleSheetStructure?.items
      if (!paper) return
      const result = {}
      const typeNum = {}
      let maxType = null
      const types = {}
      paper.forEach((item, idx) => {
        const rule = /single|multiple/
        const len = item['choices']?.length || ''
        if (!len) {
          if (!types.s) {
            types['s'] = []
            typeNum['s'] = 0
          }
          types['s'].push(idx + 1)
          typeNum['s']++
          return
        }
        if (rule.test(item.type)) {
          if (!types['w-' + len]) {
            types['w-' + len] = []
            typeNum['w-' + len] = 0
          }
          types['w-' + len].push(idx + 1)
          typeNum['w-' + len]++
        } else {
          if (!types.t) {
            types['t'] = []
            typeNum['t'] = 0
          }
          types['t'].push(idx + 1)
          typeNum['t']++
        }
      })

      Object.keys(typeNum).forEach((key, idx) => {
        if (idx === 0) maxType = key
        if (typeNum[maxType] < typeNum[key]) maxType = key
      })
      delete types[maxType]
      result.i = paper.length
      result.m = maxType
      result.o = Object.keys(types).reduce((res, key) => {
        return res = res + key + ':' + shortArr(types[key]).join(',') + ';'
      }, '')
      // console.log(setUrlQuery({ url: '', query: result }), 'result', result)
      // setUrlQuery({ url: '', query: result });
      // console.timeEnd('query run ...')

      this.downlodQuery = setUrlQuery({ url: '', query: result })
    },
    openDownload() {
      this.downloadBubblesheetModalVisible = true
      this.computedQuery()
    }
  },

  beforeCreate() {
    webviewService.pushBackButtonCallback('quiz.bubblesheet', () => this.$router.push({ name: 'teacherQuizList' }))
  },
  beforeDestroy() {
    webviewService.popBackButtonCallback('quiz.bubblesheet')
  }
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/variables';
@import 'src/assets/style/mixins';

.bubblesheet {
  &__empty-bubblesheet {
    position: fixed;
    z-index: $high-z-index;
    top: 1.88rem;
    width: 100%;
  }

  &__prompt-bar {
    position: fixed;
    z-index: $high-z-index;
    top: 2.7rem;
    width: 100%;
    height: 0.8rem;
    background: $light-red;
    color: $white;
    font-size: $h5;
    line-height: 0.8rem;
    text-align: center;
  }

  &__summary-bar {
    position: fixed;
    z-index: $high-z-index;
    top: 2.7rem;
    width: 100%;
    height: 2rem;
    background: $white;
    border-top: 1px solid $light-gray;

    &.is-move-down {
      top: 3.45rem;
    }
  }

  &__options-bar {
    position: fixed;
    z-index: $highest-z-index;
    top: 4.7rem;
    width: 100%;
    height: 1rem;
    background-color: $light-gray;
    font-size: $h5;

    &.is-move-down {
      top: 5.45rem;
    }
  }

  &__edit-count-button {
    position: absolute;
    z-index: $high-z-index;
    top: 0.4rem;
    left: 0.3rem;

    &:not(.is-disabled):active {
      opacity: 0.5;
    }

    &.is-disabled {
      color: $gray;
    }
  }

  &__download-button {
    position: absolute;
    z-index: $high-z-index;
    top: 0.4rem;
    left: 3.2rem;

    &:active {
      opacity: 0.5;
    }
  }

  &__select-all-button,
  &__unselect-all-button {
    position: absolute;
    z-index: $high-z-index;
    top: 0.4rem;
    right: 0.2rem;
    display: inline-block;
    width: 2rem;
    color: $blue;
    text-align: right;

    &:active {
      opacity: 0.5;
    }
  }

  &__item-list {
    margin: 3.55rem 0 1.3rem 0;

    &.is-move-down {
      margin-top: 4.4rem;
    }
  }

  &__operation {
    position: fixed;
    z-index: $high-z-index;
    bottom: 0;
    display: flex;
    width: 100%;
  }

  &__remove-button,
  &__edit-button,
  &__set-score-button {
    display: inline-block;
    height: 1.2rem;
    color: $white;
    flex: 1;
    font-size: $h4;
    line-height: 1.2rem;
    text-align: center;
  }

  &__remove-button,
  &__edit-button {
    border-right: 1px solid $white;
  }
}
</style>
