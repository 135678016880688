<template>
  <div class="classroom-report">
    <div class="classroom-report__table">
      <div
        class="classroom-report__table-header"
        @click="sortPopupVisible = true"
      >
        <span class="classroom-report__table-header__item">姓名</span>
        <span class="classroom-report__table-header__item">考号</span>
        <span class="classroom-report__table-header__item">得分</span>

        <span class="classroom-report__sort-button">
          <i class="fas fa-sort"></i>
        </span>
      </div>
      <sort-popup
        v-if="sortPopupVisible"
        :sort-key="sortKey"
        @click-sort-by-taker-number-button="sortByTakerNUmber"
        @click-sort-by-correct-rate-button="sortByCorrectRate"
        @click-sort-by-resulted-at-button="sortByResultedAt"
        @click-mask="sortPopupVisible = false"
      >
      </sort-popup>
      <div class="classroom-report__table-content">
        <list-sort-transition>
          <taker-card
            v-for="(entry, index) in quizTakeableEntries"
            :entry="entry"
            :key="entry.quizTaker.quizTakerId"
            :is-first="index === 0"
            @click-taker="showTakerModal"
          >
          </taker-card>
        </list-sort-transition>
      </div>
    </div>

    <modal-transition>
      <taker-modal
        v-if="takerModalVisible"
        :entry="entryForTakerModal"
        @close-modal="hideTakerModal"
        @click-remove-button="removeQuizAnswer"
        @click-edit-button="editQuizAnswer"
      >
      </taker-modal>
    </modal-transition>

    <div
      class="classroom-report__select-classroom-bar"
      :class="{ 'is-list-visible': classroomListVisible }"
      @click="classroomListVisible = !classroomListVisible"
    >
      <span class="classroom-report__classroom-name">
        <i class="fa fa-address-book-o"></i>
        {{ currentClassroom.classroomName }}
      </span>
      <span class="classroom-report__select-classroom-button">
        <i class="fa fa-angle-double-up" v-show="!classroomListVisible"></i>
        <i class="fa fa-angle-double-down" v-show="classroomListVisible"></i>
        {{ $t("TeacherQuiz.label.switch") }}
      </span>
    </div>
    <div v-if="classroomListVisible">
      <transition name="fade">
        <div class="classroom-report__classroom-list">
          <label
            class="classroom-report__classroom-item"
            v-for="classroom in report.classrooms"
            :key="classroom.id"
          >
            <div class="classroom-report__classroom-item__selector">
              <input
                type="checkbox"
                :checked="classroom === currentClassroom"
                :disabled="classroom === currentClassroom"
                @change="selectClassroom(classroom)"
              />
              <span></span>
            </div>
            <span class="classroom-report__classroom-item__classroom-name">
              {{ classroom.classroomName }}
            </span>
            <span class="classroom-report__classroom-item__summary">
              <span>
                {{ $t("TeacherQuiz.label.correctRate") }}:
                {{ formatRatio(classroom.summary.averageScoreRatio) }}
              </span>
              <span>
                {{ $t("TeacherQuiz.label.scanOverview") }}:
                {{ hasResultTakersCount(classroom) }}/{{
                  classroom.summary.takersTotal
                }}
              </span>
            </span>
          </label>
        </div>
      </transition>
      <div
        class="classroom-report__classroom-list-mask"
        @click="classroomListVisible = false"
      >
        <mask-layer></mask-layer>
      </div>
    </div>
  </div>
</template>

<script>
import ListSortTransition from "@/components/ListSortTransition";
import ModalTransition from "@/components/ModalTransition";
import MaskLayer from "@/components/MaskLayer";

import SortPopup from "./_classroom-report/SortPopup";
import TakerCard from "./_classroom-report/TakerCard";
import TakerModal from "./_classroom-report/TakerModal";

import Toast from "@/components/__mint-ui/Toast";

import urls from "@/api/teacher-urls";
import webviewService from "@/services/webview";

export default {
  components: {
    ListSortTransition,
    ModalTransition,
    MaskLayer,
    SortPopup,
    TakerCard,
    TakerModal,
  },

  props: ["report", "currentClassroom"],

  data() {
    return {
      sortPopupVisible: false,
      classroomListVisible: false,
      takerModalVisible: false,

      sortKey: null,
      entryForTakerModal: null,
    };
  },

  computed: {
    quizTakeableEntries() {
      return this.currentClassroom.quizTakeableEntries.map((e) =>
        Object.assign({}, e)
      );
    },
  },

  watch: {
    classroomListVisible(val, oldVal) {
      if (val === true && oldVal === false)
        webviewService.pushBackButtonCallback(
          "quiz.report.classroomReport.classroomList",
          () => (this.classroomListVisible = false)
        );
      else
        webviewService.popBackButtonCallback(
          "quiz.report.classroomReport.classroomList"
        );
    },
  },

  methods: {
    __removeQuizAnswer({ quizId, quizAnswerEntryId }) {
      const url = urls["quizAnswer"]({ quizId, quizAnswerEntryId });
      return this.$http.delete(url);
    },
    __editQuizAnswer({ quizId, quizAnswerEntryId, quizAnswerSheet }) {
      const url = urls["quizAnswer"]({ quizId, quizAnswerEntryId });
      return this.$http.put(url, { quizAnswerSheet });
    },

    removeQuizAnswer({ quizAnswerEntryId }) {
      const quizId = this.report.quiz.quizId;
      this.__removeQuizAnswer({ quizId, quizAnswerEntryId }).then((rep) => {
        this.takerModalVisible = false;
        this.$emit("remove-quiz-answer");
        Toast.showTip("删除成功");
      });
    },
    editQuizAnswer({ quizAnswerEntryId, quizAnswerSheet }) {
      const quizId = this.report.quiz.quizId;
      this.__editQuizAnswer({
        quizId,
        quizAnswerEntryId,
        quizAnswerSheet,
      }).then((rep) => {
        this.takerModalVisible = false;
        this.$emit("edit-quiz-answer");
        Toast.showTip("编辑成功");
      });
    },

    formatRatio(ratio) {
      return (ratio * 100).toFixed(0) + "%";
    },
    hasResultTakersCount(classroom) {
      return classroom.quizTakeableEntries.filter(
        (e) => e.quizAnswerResult !== null
      )["length"];
    },

    sortByTakerNUmber() {
      this.quizTakeableEntries.sort(
        (e1, e2) => e1.quizTaker.quizTakerNumber - e2.quizTaker.quizTakerNumber
      );
      this.sortKey = "takerNumber";
      this.sortPopupVisible = false;
    },
    sortByResultedAt() {
      this.quizTakeableEntries.sort(
        (e1, e2) => e2.quizAnswerResultedAt - e1.quizAnswerResultedAt 
      );
      this.sortKey = "resultedAt";
      this.sortPopupVisible = false;
    },
    sortByCorrectRate() {
      this.quizTakeableEntries.sort((e1, e2) => {
        if (e1.quizAnswerResult === null) return 1;
        else if (e2.quizAnswerResult === null) return -1;
        else
          return (
            e2.quizAnswerResult.summary.totalScoreRatio -
            e1.quizAnswerResult.summary.totalScoreRatio
          );
      });

      this.sortKey = "correctRate";
      this.sortPopupVisible = false;
    },
    selectClassroom(classroom) {
      this.classroomListVisible = false;
      this.sortKey = "";
      this.$emit("change-current-classroom", { classroom });
    },
    showTakerModal(entry) {
      if (entry.quizAnswerResult === null) {
        Toast.showWarning("尚未扫描该同学答题卡");
      } else {
        this.entryForTakerModal = entry;
        this.takerModalVisible = true;
      }
    },
    hideTakerModal() {
      this.entryForTakerModal = null;
      this.takerModalVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/variables";
@import "src/assets/style/mixins";

.classroom-report {
  &__table {
    margin-bottom: 1.3rem;
  }

  &__table-header {
    @include pressed(0, 0, 0, 0);

    position: relative;
    display: flex;
    padding: 0.3rem;

    &__item {
      flex: 1;
      font-size: $h5;
      // font-weight: bold;
    }
  }

  &__sort-button {
    position: absolute;
    top: 0.25rem;
    right: 0.3rem;
    font-size: $h4;
  }

  &__table-content {
    background-color: $white;
    border-bottom: 1px solid $light-gray;
  }

  &__select-classroom-bar {
    @include pressed(0, 0, 0, 0);

    position: fixed;
    z-index: $highest-z-index;
    bottom: 0;
    width: 100%;
    height: 1.2rem;
    background-color: $blue;
    color: $white;
    line-height: 1.2rem;
    text-align: left;

    &.is-list-visible {
      z-index: $highest-z-index + 2;
    }
  }

  &__classroom-name {
    @include truncate(75%);
    float: left;
    margin-left: 0.3rem;
    display: inline-block;
    font-size: $h3;
  }

  &__select-classroom-button {
    margin-right: 0.3rem;
    float: right;
    font-size: $h4;

    i {
      font-size: $h3;
    }
  }

  &__classroom-list {
    position: fixed;
    z-index: $highest-z-index + 2;
    bottom: 1.2rem;
    padding-top: 0.3rem;
    width: 100%;
    height: 5.7rem;
    max-height: 5.7rem;
    background-color: $blue;
    border-top: 1px solid $light-gray;
    box-shadow: 0 1px 1px -1px $light-gray;
    color: $white;
    overflow-y: auto;
  }

  &__classroom-item {
    margin-top: 0.1rem;
    display: block;
    height: 0.8rem;
    font-size: $h4;

    &__classroom-name {
      @include truncate(3.5rem);
      display: inline-block;
      vertical-align: middle;
    }

    &__summary {
      color: $light-gray;
      font-size: $h5;

      span {
        margin-left: 0.3rem;
      }
    }

    &__selector {
      display: inline-block;
      height: 1.2rem;
      width: 1rem;
      text-align: center;

      input {
        display: none;
      }

      span {
        position: relative;
        display: inline-block;
        width: 0.5rem;
        height: 0.5rem;
        background-color: $white;
        border-radius: 0;
        border: 1px solid $gray;
        vertical-align: middle;

        &:after {
          position: absolute;
          top: 0.1rem;
          left: 0.2rem;
          width: 0.1rem;
          height: 0.2rem;
          border: 2px solid transparent;
          border-left: 0;
          border-top: 0;
          content: "";
          transform: rotate(45deg) scale(0);
          transition: transform 0.2s, -webkit-transform 0.2s;
          -webkit-transform: rotate(45deg); // For android 4.4
        }
      }

      input:checked + span:after {
        border-color: $blue;
        transform: rotate(45deg) scale(1);
      }
    }
  }

  .fade-enter-active {
    transition: all 0.5s ease;
  }

  .fade-leave-active {
    transition: all 1s ease;
  }

  .fade-enter,
  .fade-leave-to {
    transform: translateY(1.2rem);
    opacity: 0;
  }
}
</style>
