<template>
  <div class="profile">
    <div class="profile__fixed-area" v-show="!editAccountVisible">
      <div class="profile__main">
        <div class="profile__buttons">
          <i class="profile__buttons__button fas fa-bell" @click="$router.push({ name: 'notificationList' })"></i>
          <i class="profile__buttons__button fas fa-cog" @click="$router.push({ name: 'settings' })"></i>
        </div>
        <div class="profile__account" @click="$router.push({ name: 'teacherEditAccount' })">
          <i class="profile__account__icon fas fa-user-circle"></i>
          <!-- <el-avatar class="profile__account__icon" :size="210" src="https://empty">
            <img src="@/assets/images/icons/user.svg" />
          </el-avatar> -->

          <span class="profile__account__info">
            {{ account.schoolInfo.directorySchoolName }}
            {{ getSubjectLabel(account.subject) }}
          </span>
          <mt-cell :title="account.fullname"></mt-cell>
        </div>
        <!--
        <div class="profile__core">
          <a class="profile__contact" href="tel:4008730968">
            <div class="profile__contact__icon">
              <i class="fa fa-handshake-o"></i>
            </div>
            <div>联系我们</div>
          </a>
          <div class="profile__upgrade-account" @click="prompt()">
            <div class="profile__upgrade-account__icon">
              <i class="fa fa-diamond"></i>
            </div>
            <div>升级账户</div>
          </div>
          <div class="profile__share-app" @click="shareApp()">
            <div class="profile__share-app__icon">
              <i class="fa fa-users"></i>
            </div>
            <div>分享下载</div>
          </div>
        </div>
-->
      </div>
      <div class="profile__discover">
        <iframe id="discover-iframe" src="https://mkyice-app.menco.cn/discover.html" ref="discover-iframe"
          @load="iframeLoaded" frameborder="0" allowtransparency="true" :height="iframe.height"></iframe>

      </div>

    </div>
    <div class="profile__footer">
      <footer-tab-bar current-tab="me"></footer-tab-bar>
    </div>

    <edit-account v-if="editAccountVisible" @click-back-button="editAccountVisible = false">
    </edit-account>
  </div>
</template>

<script>
  import FooterTabBar from '@/components/teacher/FooterTabBar';
  import Toast from '@/components/__mint-ui/Toast';

  import EditAccount from './_profile/EditAccount';

  import checkAccess from '@/services/check-access';
  import subjectOptions from '@/services/subject-options';
  import webviewService from '@/services/webview';

  import urls from '@/api/teacher-urls';
  import {
    findWhere
  } from 'underscore';

  export default {
    components: {
      FooterTabBar,
      Toast,
      EditAccount
    },

    data() {
      return {
        editAccountVisible: false,
        iframe: {
          ready: false,
          height: 0
        }
      };
    },

    computed: {
      account() {
        return this.$store.state.session.account;
      }
    },

    methods: {
      iframeLoaded() {
        // document.domain = 'mkyice.cn';
        this.iframe.ready = true;

        // let targetIframe = document.getElementById('discover-iframe');
        // console.log('iframe:', this.$refs['discover-iframe'].contentWindow.document.body.scrollHeight);
        // this.iframe.height = this.$refs['discover-iframe'].contentWindow.document.body.scrollHeight + 10;
      },
      receiveMessage(event) {
        if (event.data && typeof event.data === 'string' && event.data.startsWith('iframe-height')) {
          this.iframe.height = Number(event.data.split(':')[1]) + 10;
        }
      },
      openLink(link) {
        webviewService.openLink(link)
      },
      getSubjectLabel(value) {
        return (value === null) ? '' : findWhere(subjectOptions, {
          value
        })['label'];
      },

      shareApp() {
        if (webviewService.wechatIsInstalled())
          webviewService.shareAppToWechat();
        else
          Toast.showMessage('请先安装微信');
      },

      prompt() {
        Toast.showMessage('该功能即将上线');
      },

      gotoFeedback() {
        this.$router.push({
          name: 'Feedback'
        })
      }
    },

    beforeCreate() {
      checkAccess('loggedIn');
    },
    mounted() {
      window.addEventListener('message', this.receiveMessage)
    },
    beforeDestroy() {
      window.removeEventListener('message', this.receiveMessage)
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';
  @import 'src/assets/style/mixins';

  .profile {

    // Fix profle page, in order to handle scroll issue on ios platform.
    &__fixed-area {
      position: static;
      top: 0;
      right: 0;
      left: 0;
      // bottom: 0;
      // overflow: auto;
      margin-bottom: 1.333333rem;
      color: $white;
    }

    &__main {
      position: relative;
      background-color: $blue;
      z-index: 500;
      // border-radius: 0 0 10% 10%;
    }

    &__buttons,
    &__account,
    &__notification,
    &__about {
      position: relative;

      &__icon {
        position: absolute;
        z-index: $high-z-index;
        top: .3rem;
        left: .5rem;
        color: $blue;
        font-size: $h3;
      }
    }

    &__buttons {
      height: .65rem;
      display: flex;
      justify-content: flex-end;
      padding: .4rem .4rem 0 0;

      &__button {
        // font-weight: bold;
        color: $white;
        font-size: $h3;
        margin: 0 .2rem 0 .5rem;
        cursor: pointer;
      }
    }

    &__account {
      // color: $white;

      &__icon {
        color: $white;
        top: .05rem;
        left: .6rem;
        font-size: $h1 + .5;
      }

      &__info {
        position: absolute;
        z-index: $high-z-index;
        top: 1rem;
        left: 2.45rem;
        font-size: $h5;
      }
    }

    &__core {
      height: 2.2rem;
      display: flex;
      background-color: $white;
      border-bottom: 1px solid $light-gray;
    }

    &__contact,
    &__upgrade-account,
    &__share-app {
      @include pressed(0, 0, 0, 0);

      position: relative;
      padding-top: .4rem;
      display: inline-block;
      flex: 1;
      font-size: $h4;
      text-align: center;

      &__icon {
        margin-bottom: .2rem;
        color: $blue;
        font-size: $h3 + .1;
      }
    }

    &__contact {
      color: $darkest-gray;
    }

    &__upgrade-account {
      &__icon {
        color: $golden;
      }
    }

    &__discover {
      // position: fixed;
      // top: 0.65+2.7rem;
      // bottom: 1.333333rem;
      // left: 0;
      // right: 0;
      padding: .35rem;
      // overflow: auto;
      // color: #3a3a3a;
      max-width: 12rem;
      margin: auto;

      iframe {
        position: relative;
        width:100%;
        border: none;
      }

      &__card {
        border-radius: 2%;
        position: relative;
        margin-top: .5rem;
        padding: 0.4rem;
        background-color: #ffffff;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // -webkit-box-shadow: 1px 1px 5px 0px rgba(214, 214, 214, 1);
        // -moz-box-shadow: 1px 1px 5px 0px rgba(214, 214, 214, 1);
        // box-shadow: 1px 1px 5px 0px rgba(214, 214, 214, 1);

        .background-image {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &__header {
          position: relative;
          z-index: 10;

          span {
            display: block;
            margin-bottom: .2rem;
          }

          .title {
            font-size: .45rem;
            font-weight: bold;

          }

          .subtitle {
            font-size: .35rem;
            font-weight: lighter;
            color: $dark-gray;
          }

        }

        &__footer {
          position: relative;
          z-index: 10;
          margin-top: .5rem;

          span {
            display: block;
            font-size: .3rem;
          }

          .title {
            font-size: .45rem;
            font-weight: bold;
          }

          .link {
            color: $blue;
            font-weight: lighter;
          }

          .badge {
            background-color: $blue;
            font-weight: lighter;
            color: white;
            padding: .1rem .15rem;
            border-radius: 5%;
          }
        }


      }
    }
  }
</style>